<template>
  <div>
    <v-data-table data-cy="customers" :items="customers" :headers="headers" :search="search">
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="{ name: 'CustomerDetail', params: { customerId: item.id } }">{{ item.name }}</router-link>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="sendMail" @click.stop="sendMessageDialog(item.id)">mdi-email</v-icon>
      </template>
      <template v-slot:top>
        <v-text-field v-model="search" :label="$t('customers.search')"></v-text-field>
      </template>
    </v-data-table>

    <v-dialog v-model="showMessageDialog">
      <v-card>
        <v-form data-cy="messageDialog">
          <v-card-title>Contact Customer</v-card-title>
          <v-card-text>
            <v-text-field data-cy="messageSubject" v-model="message.subject" label="Message subject">
            </v-text-field>
            <v-textarea name="messageContent" label="Message" auto-grow v-model="message.content">
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="cancelMessage" @click="showMessageDialog = false">Cancel</v-btn>
            <v-btn data-cy="sendMessage" @click="sendMessage()" color="primary">Send</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'CustomersHome',
  components: {},

  data: () => ({
    search: '',
    headers: [
      { text: i18n.t('terms.tableHeaders.name'), align: 'start', value: 'name' },
      // { text: i18n.t('customerTable.lastPurchase'), align: "start", value: "lastPurchase" },
      { text: i18n.t('terms.tableHeaders.actions'), value: 'actions', sortable: false }
    ],
    customers: [],
    message: {},
    selectedCustomerId: null,
    showMessageDialog: false
  }),

  beforeMount () {
    this.loadCustomers()
  },

  methods: {
    loadCustomers () {
      fetch('/api/customers', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.customers = data
        })
    },

    sendMessageDialog (customerId) {
      this.selectedCustomerId = customerId
      this.showMessageDialog = true
    },

    sendMessage () {
      fetch(`/api/customers/${this.selectedCustomerId}/send-message`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          subject: this.message.subject,
          content: this.message.content
        })

      })
      this.showMessageDialog = false
    }

  }
}
</script>

<style scoped>
.dress-as-link {
  text-decoration: underline;
}
</style>
